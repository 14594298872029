import React from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Link } from 'gatsby';
import Image from '../elements/Image';

import 'swiper/css/pagination';

export const FlexArticleRelatedResources = (props) => {
  const { relatedItem } = props;

  return (
    <div className="pt-1 pb-32 container">
      <h3 className="text-center t-38 text-black font-bold mb-16 text-center">Explore more with Repool</h3>
      <Swiper
        modules={[Pagination]}
        className="max-w-[50rem]"
        spaceBetween={50}
        pagination={{ clickable: true, el: '.pagination' }}
        breakpoints={{
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 3,
          },
        }}
      >
        {relatedItem?.relatedPosts?.map((item, i) => (
          <SwiperSlide key={i}>
            {item.postType === 'wiki' ? (
              //  Wiki Preview
              <SwiperItem item={item?.wikiPost} post={item?.wikiPost?.postTypeWiki} />
            ) : item.postType === 'blog' ? (
              //  Blog Preview
              <SwiperItem item={item?.blogPost} post={item?.blogPost?.postTypeSingle} />
            ) : (
              // Market Commentary Preview
              <SwiperItem item={item?.marketPost} post={item?.marketPost?.postTypeSingle} />
            )}
          </SwiperSlide>
        ))}
        <div className="pt-12 related-resource-pagination pagination flex justify-center" slot="pagination"></div>
      </Swiper>
    </div>
  );
};

export const SwiperItem = ({ item, post }) => {
  return (
    <Link to={item?.uri}>
      <div className="aspect-[265/214] mb-9 rounded-lg overflow-hidden safari-overflow">
        <Image
          className="w-full h-full object-cover object-center"
          objectFit={'cover'}
          image={item?.featuredImage?.node}
        />
      </div>
      <h4 className="t-20-h text-black font-bold leading-[1.5]">{item?.title}</h4>
      <div
        className="text-black/60 line-clamp-3"
        dangerouslySetInnerHTML={{ __html: post?.previewgroup?.previewDescription }}
      ></div>
    </Link>
  );
};
