import React from "react";
import { Link } from "gatsby";

const WikiCta = ({
  cta
}) => {

  return(
    <section>
       <hr className="border-t border-borderGrey2 w-[96%] max-w-[892px] mx-auto mb-12 md:mb-[74px]"/>
       <div className="container overflow-hidden !max-w-[780px] relative text-center pt-12 pb-20 md:py-0 md:mt-[120px] md:mb-[200px] lg:mt-[166px] lg:mb-[260px]">
          <h2 className="t-52 max-w-[740px] font-medium mx-auto mb-12 md:mb-20">{cta?.heading ? `${cta.heading}` : `Want to gain access to new and diverse fund managers?`}</h2>
          {cta?.button?.url ?
            <Link className="inline-flex justify-center w-full md:w-auto rounded-[45px] border-2 border-navy/[.2] t-18 font-heading bg-blue text-white font-medium hover:bg-navy transition-colors duration-300 px-[50px] py-[9px]" to={cta?.button?.url}>
              {cta?.button?.title}
            </Link>
          :
            <Link className="inline-flex justify-center w-full md:w-auto rounded-[45px] border-2 border-navy/[.2] t-18 font-heading bg-blue text-white font-medium hover:bg-navy transition-colors duration-300 px-[50px] py-[9px]" to={`/contact/`}>
              Become an investor
            </Link>
          }
        <div className="absolute top-0 md:top-0 bottom-0 my-auto left-4"><Star /></div>
        <div className="absolute top-auto bottom-6 md:bottom-0 my-auto md:top-0 md:translate-y-28 right-4"><Star /></div>
       </div>
       
    </section>
  );
};

export default WikiCta;

export const Star = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.32 25C12.4407 21.6431 13.8295 18.465 16.1943 16.1337C18.5591 13.8025 21.7156 12.4999 25 12.5C21.7156 12.5001 18.5591 11.1976 16.1943 8.86637C13.8295 6.53515 12.4407 3.35693 12.32 0C12.1989 3.29213 10.8588 6.41408 8.5713 8.73349C6.28376 11.0529 3.2198 12.3963 0 12.4916C3.22252 12.5872 6.28873 13.9329 8.5767 16.2559C10.8647 18.5788 12.203 21.7049 12.32 25Z" fill="#12C0C0" fillOpacity="0.8"/>
  </svg>
);